@tailwind base;
@tailwind components;
@tailwind utilities;

/* SuperRally Mark */
.clip-path {
    clip-path: polygon(50% 0%, 100% 0, 100% 35%, 100% 60%, 50% 60%, 50% 100%, 0 100%, 0% 70%, 0% 35%, 0 0);
}
/* Home section */
.section-finished {
    background-color: red;
}
.section-started {
    background-color: green;
}
.section-not-started {
    background-color: #999;
}
.section-cancelled {
    background-color: #333;
}
.section-neutralized {
    background-color: orange;
}
/* antd */
.ant-modal .ant-modal-title {
    padding-right: 20px;
}
